import * as Sentry from '@sentry/vue'

export default defineNuxtRouteMiddleware(async () => {
  const supabase = useSupabaseClient()
  const { data: { user }, error: userError } = await supabase.auth.getUser()

  if (user) {
    if (import.meta.env.DEV) return
    Sentry.setUser({ id: user.id, email: user.email })
  }

  if (userError) {
    return navigateTo('/auth')
  }

  if (!user) {
    return navigateTo('/auth')
  }
})
